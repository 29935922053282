import React, { useEffect, useState } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Burger from "./Burger"
import Navigation from "./Navigation"
import { HeaderStyles } from "../styles/NavStyles"

const Header = ({Logo}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50)
    })
  }, [])

  return (
    <HeaderStyles className={scroll ? "scrolled" : null}>
      <div className="innerContent">
        <Burger />
        <div className="logo-cont">
          <Link to="/">
            <span>{data.site.siteMetadata.title}</span>
          </Link>
        </div>

        <Navigation />
        <div className="menu-item contact desktop">
          <span>Contact</span>
        </div>
      </div>
    </HeaderStyles>
   );
}

export default Header;
