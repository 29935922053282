import styled from "styled-components"

export const HeaderStyles = styled.header`
  position: relative;
  z-index: 99;
  transition: box-shadow var(--transMed) ease;
  transition: all 1s ease;

  margin: 0 auto;
  background: #BFBEB9;
  display: flex;
  justify-content: center;

  // &.scrolled {
  //   background: #bfbeb97a;
  //   position: fixed;
  //   top: 0;
  //   width: 100%;
  // }

  .innerContent {
    max-width: 1686px;
    width: 100%;
    padding: 5px 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    grid-template-columns: 200px auto;
    grid-gap: 1rem;
  }

  @media(min-width: 1200px) {
    padding: 5px 20px;
  }

  .logo-cont {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -40px;
    width: 100%;
    z-index: 2;

    @media (min-width: 1200px) {
      align-items: unset;
      margin-left: 0;
      width: auto;
    }

    span {
      font-family: 'Freight Medium', serif;
      line-height: 48px;
      &:hover {
        color: #ade649;
      }

      @media(max-width: 768px) {
        line-height: 68px;
      }
    }

    a {
      display: inline-block;
      line-height: 2.5rem;
      color: var(--black);
      font-size: 1.75rem;
      // text-transform: uppercase;
      text-decoration: none;
      transition: var(--transMed);
      position: relative;

      @media (min-width: 1200px) {
        font-size: 2.375rem;
      }

      &:hover {
        color: var(--primaryColor);
      }
    }

    img {
      vertical-align: middle;
      width: 125px;

      @media (min-width: 768px) {
        width: 150px;
      }
    }

    h2 {
      margin: 0;
      font-size: 1.5rem;

      @media (min-width: 768px) {
        font-size: 1.75rem;
      }
    }
  }
`

export const NavigationStyles = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
  transform: translateX(100%);
  transition: var(--transMed);

  @media (max-width: 1199px) {
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(50px);
  }

  @media (min-width: 1200px) {
    // background-color: rgba(255, 255, 255, 0.5);
    background: #fff;
    bottom: auto;
    max-width: 390px;
    height: 100vh;
    transform: translateX(-100%);
  }

  &.open {
    transform: translateX(0%);
  }

  button {
    height: 0;
    margin: 0;
    padding: 0;
    width: 0;
  }

  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1200px) {
      align-items: flex-start;
    }
  }

  ul {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    text-transform: capitalize;

    @media (min-width: 1200px) {
      padding-bottom: 20.3125rem;
      padding-top: 5.3125rem;
    }

    li {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.15rem;
      font-family: "Playfair Display";
      font-weight: 300;

      @media (max-width: 1199px) {
        padding-left: 0 !important;
      }

      @media (min-width: 1200px) {
        padding-left: 3.3125rem;
      }
    }


    @media (min-width: 768px) {
      ul {
        align-items: center;
        li {
          padding-left: 2rem;
          justify-content: flex-end;
        }
      }
    }
  }

  .first-nav-link {
    position: absolute;
    top: 19px;

    @media (min-width: 1200px) {
      display: none;
    }

    a {
      font-size: 1.75rem;
      text-transform: none;
    }
  }
`

export const BurgerStyles = styled.button`
  height: 27px;
  border: none;
  background-color: transparent;
  font-family: var(--serif);
  color: var(--charcoal);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1.15rem;
  z-index: 4;

  &:focus {
    outline: none;
  }

  > div {
    span {
      border-top: 3px solid #000;
      border-radius: 25px;
      display: block;
      transition: width 0.3s ease;
      width: 25px;

      &:nth-child(1) {
        transform: translateY(-8px);
      }

      &:nth-child(3) {
        transform: translateY(8px);
      }
    }
  }

  &:hover {
    > div {
      span {
        border-color: #B0E74B;
      }
    }
  }

  &.open {
    z-index: 2;
    > div {
      span {
        &:nth-child(2) {
          width: 20px;
        }

        &:nth-child(3) {
          width: 10px;
        }
      }
    }
  }

`
export const CloseBtnStyles = styled.button`
  .close {
    bottom: 32px;
    position: absolute;
    right: 32px;
    width: 32px;
    height: 32px;
    color: #000;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    color: #000;
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #000;
  }
  .close:before {
    color: #000;
    transform: rotate(45deg);
  }
  .close:after {
    color: #000;
    transform: rotate(-45deg);
  }
`
