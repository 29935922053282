import React from "react"
import Header from "./Header"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"
import LayoutStyles from "../styles/layout.css"
import Logo from "../content/images/bonneville-logo.svg"
import { Helmet } from "react-helmet";

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <link href="https://use.typekit.net/qob7rfu.css" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;600;700&display=swap" rel="stylesheet"/>
      </Helmet>
      <Typography />
      <Header Logo={Logo} />
      <main className="main-body">{children}</main>
    </>
  )
}

export default Layout
