import { Link } from "gatsby"
import React, { useContext } from "react"
import { mainMenuItems } from "../constants/menu-items"
import { NavigationStyles, CloseBtnStyles } from "../styles/NavStyles"
import MenuContext from "./MenuContext"

const Navigation = () => {
  const [isOpen, setNav] = useContext(MenuContext)

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  return (
    <NavigationStyles className={isOpen ? "open" : "closed"}>
      <ul>
        {mainMenuItems.map((item, index) => (
          <li key={`menu-item-${index}`} className={`${index === 0 && 'first-nav-link'} nav-link`}>
            <Link to={item.path} onClick={toggleNav}>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>

      <CloseBtnStyles
        onClick={toggleNav}
        type="button"
        className={`${isOpen ? "open" : "closed"} close-btn`}
      >
         <a href="#" className="close" role="button" aria-label="next"><i aria-hidden="true"></i> </a>
      </CloseBtnStyles>

    </NavigationStyles>
  )
}

export default Navigation
