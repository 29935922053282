import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  :root {
    --heavyWeight: 900;
    --transMed: 0.3s;
    --transSlow: 0.5s;
    --black: #2d2828;
    --charcoal: #3e3e3e;
    --primaryColor: #939393;
    --serif: "Playfair Display", serif;
    --sansSerif: "Lato", sans-serif;
    --h1: 2rem;
    --h2: 1.8rem;
    --h3: 1.5rem;
    --h4: 1.25rem;
    --h5: 1.15rem;
    --h6: 1rem;
    --footerMenuItem: 0.85rem;
    --para: 1rem;
    --spacing: 1rem;

    @media (min-width: 768px) {
      --h1: 2.6rem;
      --h2: 2.4rem;
      --h3: 1.75rem;
      --h4: 1.5rem;
      --h5: 1.25rem;
      --h6: 1.1rem;
      --footerMenuItem: 1rem;
      --para: 1.1rem;
    }

    @media (min-width: 1200px) {
      --h1: 3rem;
      --h2: 2.8rem;
      --h3: 2rem;
      --h4: 1.75rem;
      --h5: 1.35rem;
      --para: 1.15rem;
    }
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-family: ACaslonPro-Regular, serif;
    font-style: normal;
    color: var(--black);
    font-size: var(--para);
    margin: 0;
    background: #BFBEB9;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'GrotesqueNo9', sans-serif!important;
  }

  p {
    font-size: var(--para);
    line-height: 1.35;

    @media (min-width: 768px) {
      line-height: 1.5;
    }
  }

  a {
    color: var(--charcoal);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &:hover,
    &:visited {
      color: #B0E74B;
    }
  }

  .main-body {
    padding: 0 0 80px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .nav-link {
    font-weight: var(--heavyWeight);
    list-style: none;
    display: inline;

    a {
      display: inline-block;
      text-decoration: none;
      position: relative;
      font-size: 1.375rem;
      text-transform: uppercase;
      -webkit-transition: var(--transMed);
      transition: var(--transMed);
      color: var(--black);
      line-height: 1.75rem;

      @media (min-width: 1200px) {
        font-size: 1.2rem;
      }

      &:hover {
        color: #B0E74B;

        &::after {
          width: 0;
          left: 100%;
        }
      }
    }
  }

  .btn {
    color: var(--black);
    text-decoration: none;
    border: none;
    background: none;
    font-family: var(--serif);
    padding: 0;
    font-size: 1rem;
    display: inline-block;
    line-height: 30px;
    position: relative;

    &-link {
      border: none;
      background-color: transparent;
      font-size: var(--h5);
      padding: 0;
      display: flex;
      font-family: var(--serif);
      color: var(--charcoal);
      text-decoration: none;
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 0.1rem;
        width: 100%;
        background-color: var(--charcoal);
        left: 0;
        bottom: -0.25rem;
        opacity: 1;
        transition: opacity var(--transSlow);
      }

      &:hover,
      &:focus {
        cursor: pointer;

        &:after {
          opacity: 0.15;
        }
      }

      &:visited {
        text-decoration: none;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important; /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important; /* 3 */
  }
  .sr-only-focusable:focus,
  .sr-only-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
  }
  .mb-350 {
    margin-bottom: 350px;
  }


.pl100 {
    padding-left: 100px;
}

.Rightauto {
    margin-right: auto;
}

.Leftauto {
    margin-left: auto;
}

.text-right {
  justify-content: flex-end;
}

.ten {
  flex-basis: 10%;
}

.twenty {
  flex-basis: 20%;
}
.thirty {
  flex-basis: 30%;
}

.forty {
  flex-basis: 40%;
}

.fifty {
  flex-basis: 50%
}

.sixty {
  flex-basis: 60%
}

.seventy {
  flex-basis: 70%
}
.eigthy {
  flex-basis: 80%
}
.ninety {
  flex-basis: 90%
}

.full {
  flex-basis: 100%
}

.center {
  justify-content: center;
}

.empty-height {
  height: 167px;
}

.continue-link {
  text-align: center;
  margin-top: 150px;
}

.continue-link a {
  color: #fff;
  text-decoration: one;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 57px;
  font-family: 'GrotesqueNo9', san-serif
}

.continue-link a:hover{
  color: #B0E74B;
}

#science {
  background-size: contain!important;
  background-position: center 25px!important;
}

.p-0 {
  padding-bottom:  0!important;
}
.empty {
  display: none;
}

.flex-container {
    display: flex;
    max-width: 1436px;
    margin: 0 auto;
    padding: 0 0px;
}




@media(max-width: 768px) {
  .empty-height {
    height: auto;
  }
  .mb-350 {
    margin-bottom: inherit;
  }
  .pl100 {
    padding-left: 0;
  }

  // #personal {
  //   height: 600px;
  // }
  // #homepage {
  //   height: 250px;
  // }
  .row-wrapper.one {
    position: absolute;
    bottom: 85%;
  }
  .row-wrapper.two {
    position: absolute;
    top: 94%;
  }
  .text-right {
    justify-content: inherit;
  }
  .Leftauto {
    margin-left: inherit;
  }
  .Rightauto {
      margin-right: inherit;
  }
  .center {
    justify-content: inherit;
  }
  &#homepage {
    margin-top: 100px;
  }
}

  @media(max-width: 440px) {
    &#homepage {
      margin-top: 75px;
      margin-bottom: 250px;
    }
    &#losAngelesHero {
      margin-top: 120px;
    }
    .continue-link {
      padding: 0 40px;
    }
    .continue-link a {
      font-size: 32px;
    }
    #businessHero {
      margin-bottom: 100px;
    }
    #science {
      margin-bottom: 150px;
    }
    #politics {
      margin-bottom: 150px;
    }
  }

  @media(max-width: 320px) {
    .continue-link a {
      font-size: 25px;
    }
    #personal {
      margin-top: 75px;
    }
    #art {
      margin-top: 135px;
    }
    #science {
      margin-top: 75px;
    }
    #education {
      margin-top: 95px;
    }
    #politics {
      margin-top: 95px;
    }
    #businessHero {
      margin-top: 45px;
    }
  }

.menu-item {
  font-size: 1.25rem;
  text-decoration: none;
  text-transform: uppercase;

  &.desktop {
    display: none;

    @media(min-width: 1200px) {
      display: block;
      visibility: hidden;
      opacity: 0;
      width: 25px;
      z-index: -1;
    }

    span {
      @media(min-width: 1200px) {
        font-size: 1.125rem;
      }
    }
  }
}


`

export default GlobalStyles
